* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  letter-spacing: .4px;
  font-size: 14px;
}
h3 {
  font-size: 18px;
}
h1 {
  font-size: 24px;
}
body {
  overflow-x: hidden;
  background-color: #f7f7f7;
}

.header {
  transition: all 1s ease-in-out;
}
.heroImg {
  width: 100%;
  max-height: 100vh;
}
.selectSec {
  position: absolute;
  bottom: 0;
  width: 90%;
  margin: auto;
  left: 0;
  right: 0;
  padding: 32px 52px;
  background: #fff;
  z-index: 9;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.selectHolder {
  padding: 32px 0 0;
  display: flex;
  justify-content: space-between;
  max-width: 50%;
  align-items: center;
}
.selectHolder .ant-select {
  width: 35%;
}
button {
  background: #2060ad;
  border-radius: 4px;
  color: #fff;
  border-color: #2060ad;
  cursor: pointer;
  letter-spacing: .4px;
  padding: 8px 12px;
}

.ant-carousel {
  margin-top: 8%;
}
.slick-slide img {
  width: 132px;
  margin: auto;
  height: auto;
}
.slick-dots.slick-dots-bottom {
  bottom: -62px;
}
.slick-dots.slick-dots-bottom li,.slick-dots.slick-dots-bottom li button {
  background: linear-gradient(to right, rgba(31,126,193,1) 0%, rgba(32,64,153,1) 51%, rgba(32,64,153,1) 100%);;
  height: 12px;
}
li.slick-active {
  border: 1px solid;
    width: 22px;
}
.aboutContainer {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  margin: auto;
  background: linear-gradient(to right, rgba(31,126,193,1) 0%, rgba(32,64,153,1) 51%, rgba(32,64,153,1) 100%);
  align-items: center;

}
.singleAbout {
  padding: 0;
    text-align: justify;
    padding-left: 72px;
}
.aboutContainer>div:first-child {
  width: 50%;
}
.aboutContainer>div:last-child {
  width: 50%;
}
.aboutContainer>div img {
  display: block;
  width: 75%;
  margin-left: auto;
  margin-right: 72px;
}
.aboutContainer ul {
  padding-right: 22px;
  padding-top: 32px;
  list-style: none;
}
.aboutContainer ul li {
  margin-bottom: 18px;
  line-height: 24px;
  font-size: 15px;

}
.batteryStructure  img{
  width: 75%;
  margin: auto;
  display: block;
  margin-top: 62px;
}
.ant-select-selection-placeholder {
  font-size: 14px;
}
.batteryStructure h1 {
  text-align: center;
}
.footer {
  background: linear-gradient(to right, rgba(31,126,193,1) 0%, rgba(32,64,153,1) 51%, rgba(32,64,153,1) 100%);
  margin-top: 122px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nd,.rd {
    object-fit: cover;
}
.footerFirst {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .footerFirst::after {
 content: '';
 height: 2px;
 background-color: #fff;
 width: 90%;
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 margin: auto;
 
} */
.footer img {
  max-width: 82px;
  display: block;
}
.hero {
  padding-top: 62px;
}
.ant-menu {
  padding: 12px;
  padding: 12px 42px;
  background: linear-gradient(to right, rgba(250,250,250,1) 0%, rgba(32,64,153,1) 51%, rgba(32,64,153,1) 100%);
  color: #fff;
  padding-bottom: 11px;
}
.innerNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.innerNav h3 {
  font-family: sans-serif;
  letter-spacing: 3px;
}



/* FORM CSS */


input {
  overflow: hidden;
}

.section-title {
  position: relative;
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 35px;
  text-align: center;
  margin-bottom: 82px;
}
.gallaryContainer .slick-dots {
  display: none !important;
}

.sec-pad {
  padding: 32px;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  margin: auto;
}
.aboutContainer,.progressSec,.batteryStructure,.productsSec,.contact-sec,.gallaryContainer {
  margin-top: 132px;
}
#productsSec>.title {
  margin-bottom: 72px;
  margin-top: 32px;
}
.batteryStructure {
  background: linear-gradient(to right, rgba(31,126,193,1) 0%, rgba(32,64,153,1) 51%, rgba(32,64,153,1) 100%);
    color: #fff;
    padding: 52px 0;
    padding-bottom: 72px;
}
.contact-sec {
  align-items: center;
  margin-top: 32px;
  display: flex;
  background:linear-gradient(to right, rgba(31,126,193,1) 0%, rgba(32,64,153,1) 51%, rgba(32,64,153,1) 100%);
}
.contact-sec h1 {
  color: #fff;
}
.progressBot .progress-bar.animate {
  background: linear-gradient(to right,#FF0000, #8B0000);
}
.contact-sec .contact-ul li,
.contact-ul b {
  font-size: 20px;
  margin: 10px 0;
  word-wrap: break-word;
}

.contact-sec .contact-ul i {
  font-size: 18px;
  padding: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.contact-detail a {
  color: #000;
  text-decoration: none;
}

.contact-sec .contact-ul li b:hover {
  color: #fff;
}
.singleGallary img{
  max-width: 100% !important;
  width: 100%;
  max-height: 400px !important;
  object-fit: contain;
}
iframe {
  width: 100%;
  height: 300px;
}

.contact-sec .contact-ul li .fa-location-dot {
  color: #f44337;
  border: 2px solid #f4433790;
}

.contact-sec .contact-ul li .fa-phone {
  color: #00b055;
  border: 2px solid #00b05590;
}

.contact-sec .contact-ul li .fa-envelope {
  color: #ff6347;
  border: 2px solid #ff634790;
}
.svg-inline--fa {
  font-size: 18px;
}

.contact-detail span {
  width: 400px;
  display: flex;
  justify-content: start;
  margin-top: 32px;
}
.contact-detail span a {
  font-size: 20px;
  padding: 6px 12px;
  color: #000;
  border-radius: 50%;
  margin: 0px 5px;
}
.contact-detail span .fb,.contact-detail span .twitter {
  color: #fff;
  background: #3B5998;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-detail span .fb:hover {
  color: #fff;
  background-color: #3B5998;
}

.contact-detail span .insta {
  color: #fff;
 background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
}
.contact-detail span .insta:hover {
  color: #fff;
 background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
}

.footer h1,.footer h3 {
font-size: 15px;
}
.footer p {
  font-size: 13px;
  }

form.contFrm {
  max-width: 396px;
  margin: auto;
}

.inptFld {
  width: 100%;
  height: 50px;
  margin: 0 0 10px;
  border-radius: 8px;
  padding: 12px 20px !important;
  font-size: 14px;
  color: #000;
  border: 1px solid #f5f5f5;
}

.inptFld:focus {
  outline-offset: -4px;
  outline: 1px solid #f93;
}

.contFrm textarea {
  height: 75px;
  padding-top: 5px;
}
.inptBtn {
  height: 50px;
  border: 0;
  background: #00b055;
  font-size: 14px;
  color: #fff;
  margin: auto;
  letter-spacing: 1px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
}
.singleProgress.end {
  text-align: center;
    max-width: 75%;
    margin: auto;
    margin-top: 92px;
}
.singleProgress.end .progressBot {
  max-width: 75%;
  margin: auto;
  margin-bottom: 22px;
}
.contact-sec button {
  background: transparent;
  border-color: #fff;
}
/* Responcive css Start */

@media (max-width: 991px) {
  .sec-pad {
    padding: 20px 0 0px;
  }

  .contact-sec .contact-ul li,
  .contact-ul b {
    font-size: 18px;
  }

  .contact-sec .contact-ul i {
    font-size: 14px;
    padding: 6px;
    margin-right: 6px;
  }

  .inptFld {
    height: 40px;
    margin: 0 0 10px;
    padding: 0 14px;
    font-size: 14px;
  }
}
.deskBanner {
  display: block;
}
.mobileBanner {
  display: none;
}

@media (max-width: 767px) {
  .ant-menu-title-content {
    padding: 4px !important;
  }
  .innerNav a {
    font-size: 13px !important;
  }
  .batteryStructure img {
    width: 100% !important;
    margin-top: 18px !important;
  }
  .selectHolder .ant-select {
    width: 30%;

}
  .deskBanner {
    display: none;
  }
  .mobileBanner {
    display: block;
  }
  .contact-detail span {
    width: auto;
  }
  .contact-detail span a {
    font-size: 18px;
    padding: 5px 10px;
    color: #000;
    border-radius: 50%;
    margin: 0px 5px 20px;
  }
}

@media (max-width: 575px) {
  .section-title {
    font-size: 26px;
    font-weight: 500;
  }
  .contact-sec {
    border-radius: 10% 10% 0% 0% / 5% 5% 0% 0%;
  }

  .contact-sec .contact-ul i {
    border: none;
  }
  .inptFld {
    height: 36px;
    margin: 0 0 8px;
    padding: 0 14px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .contact-sec .contact-ul li,
  .contact-ul b {
    font-size: 16px;
  }
}
.container {
  width: 90%;
  margin: auto;
  padding: 0;
  min-height: fit-content;
  overflow: hidden;
}
.content.open {
  margin-top: 122px;
}
.container>.row {
  display: flex;
  width: 100%;
}
.col-md-6 {
  width: 50%;
}
.contact .batteryStructure {
  display: none;
}
.contact .about {
  padding-top: 0;
}
.contact .about>.about {
  display: none;
}
.contactBanner img{
  width: 100%;
  max-height: 450px;
  object-fit: cover;
}
.socialSec img {
  max-width: 200px;
}
.socialSec {
  text-align: center;
  padding: 112px 0 0 0;
}



/* PRODUCT PAGE */


:root {
  --breakpoint-xs: 600px;
  --breakpoint-sm: 768px;
  --red: #e41919;
}


.mystyle-products {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
    margin: auto;
}
@media (max-width: var(--breakpoint-xs)) {
  .mystyle-products {
    display: block;
  }
}
.mystyle-products.slider-products .product {
  width: auto;
  margin-bottom: 0;
}
.mystyle-products .product {
  width: calc(25% - 12px);
  margin-bottom: 20px;
  position: relative;
  padding: 20px;
  background: #fff;
  margin-right: 12px;
  cursor: pointer;
}
@media (max-width: var(--breakpoint-sm)) {
  .mystyle-products .product {
    width: 50%;
  }
}
@media (max-width: var(--breakpoint-xs)) {
  .mystyle-products .product {
    width: auto;
  }
}
.mystyle-products .product:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 7;
}
.mystyle-products .product:hover .btn-circle {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.mystyle-products .product h3 {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  height: 39px;
  overflow: hidden;
}
.mystyle-products .product p{
  font-size: 13px;
  margin-bottom: 20px;
}
@media (max-width: var(--breakpoint-xs)) {
  .mystyle-products .product h3 {
    height: auto;
  }
}
.mystyle-products .product > a {
  position: relative;
  display: block;
  color: #333;
  text-decoration: none;
}
.mystyle-products .product > a:hover {
  text-decoration: none;
}
.mystyle-products .product .add_to_cart_button {
  display: none;
}
.mystyle-products .product .attachment-shop_catalog {
  display: block;
  max-width: 100%;
  height: 200px;
  object-fit: cover;
  margin: auto;
}
.product li {
  font-size: 12px;
  margin-bottom: 4px;
}
.mystyle-products .product .btn-circle {
  border-radius: 2px;
    width: 100%;
    height: 100%;
    line-height: 30px;
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    color: #fff;
    transform: translateY(-20px);
    opacity: 0;
    visibility: hidden;
    transition: color 0.5s 0.001s ease-out, background 0.3s 0.001s ease-out, visibility 0.5s 0.25s ease-out, opacity 0.5s 0.25s ease-out, transform 0.5s 0.25s ease-out;
    background: linear-gradient(to bottom, rgba(250,250,250,.2) 0%, rgba(32,64,153,.85) 51%, rgba(32,64,153,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    letter-spacing: 0.5px;
}
.btn-review div {
  border: 2px solid;
    padding: 6px 12px;
    border-radius: 4px;
}
.mystyle-products .product .price {
  font-size: 14px;
  margin-top: 12px;
  display: block;
}
.mystyle-products .product .price ins {
  text-decoration: none;
  font-weight: 700;
  white-space: nowrap;
}
.mystyle-products .product .price del {
  color: #666;
  font-size: 11px;
  padding-right: 7px;
  white-space: nowrap;
}
.mystyle-products .product .price .sale-tag {
  color: red;
  font-size: 12px;
  padding-left: 7px;
  font-weight: 700;
}
.mystyle-products .onsale {
  z-index: 6;
  position: absolute;
  top: 15px;
  left: -20px;
  padding: 2px 10px;
  background: var(--red);
  color: #fff;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 0 5px 5px 0;
  height: 25px;
  line-height: 25px;
  font-size: 0.8rem;
  font-weight: normal;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;
}
.mystyle-products .onsale:before, .mystyle-products .onsale:after {
  content: "";
  position: absolute;
}
.mystyle-products .onsale:before {
  width: 7px;
  height: 33px;
  top: 0;
  left: -6.5px;
  padding: 0 0 7px;
  background: inherit;
  border-radius: 5px 0 0 5px;
}
.mystyle-products .onsale:after {
  width: 5px;
  height: 5px;
  bottom: -5px;
  left: -4.5px;
  border-radius: 5px 0 0 5px;
  background: #800;
}
.title {
  text-align: center;
}
.progress-bar-container {
  width: 100%;
  height: 12px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 12px;
  background:linear-gradient(to right, rgba(31,126,193,1) 0%, rgba(32,64,153,1) 51%, rgba(32,64,153,1) 100%);
  transition: width 1.5s linear;
}

.progress-bar.animate {
  animation: progress-bar-animation 1.5s linear forwards;
}

@keyframes progress-bar-animation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.headerFixed {
  position: fixed;
  width: 100%;
  z-index: 99;
}
.progressTop,.progressBot {
  display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 4px;
}

.progressTop>span,.progressBot>span {
  margin-left: 22px;
}
.progressSec>div{
  margin: auto;
  padding-top: 82px;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}
.progressSec>div>div {
  width: 90%;
  margin: auto;
}
.progressSec>div>div:first-child {
  width: 100% !important;
}


.progressSec>h1 {
  text-align: center;
}
.progressSec span {
  font-size: 14px;
}
.singleProgress {
  margin-bottom: 32px;
}
.progressSec p {
  font-weight: 600;
  margin-bottom: 6px;
}

.content {
  overflow: hidden;
  transition: height 1.3s linear;
  height: 100%;
  padding: 32px;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  padding-top: 18px;
}
.content.open>div {
  width: 40%;
}
.content.open>div.bikeGallery{
  width: 60%;
}
.singleGallary {
  display: flex;
  flex-wrap: wrap;
  padding: 32px;
}
.open {
  height: 100%;
}
.content img {
  max-width: 312px;
  max-height: 212px;
}
.content h4 {
  margin-top: 18px;
}
.content p {
  margin-top: 12px;
}
.content table tr,.content table td{
  border: 2px solid rgba(32,64,153,1);
    padding: 12px;
}
.content table {
  margin: 12px 0;
}
.ant-modal-footer {
  display: none;
}
.ant-modal-body .col-md-6 {
  border: 1px solid #f5f5f5;
  margin: 12px;
  padding-top: 4px;
}
.ant-modal-header {
  padding: 12px;
  margin-bottom: 22px;
  text-align: center;
}
.contact .progressSec {
  display: none;
}
.contact .about>div {
  display: none;
}
.innerNav a {
  color: #fff;
}
.singleBox > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.singleBox img {
  max-width: 332px;
  height: auto;
}
.singleBox h3 {
  font-family: inherit;
  color: initial;
  font-weight: 100;
  padding: 0 0 6px 16px;
}
.singleDescription {
  padding: 32px;
  background: linear-gradient(to right, rgba(31,126,193,1) 0%, rgba(32,64,153,1) 51%, rgba(32,64,153,1) 100%);
  color: #fff;
  line-height: 24px;
}
.c-section {
  max-width: 90%;
    margin: 112px auto;
}
.singleBox {
  margin-bottom: 52px;
}
.left{
  width: 75%;
  margin-right: auto;
}
.right{
  width: 75%;
  margin-left: auto;
}
.c-section>h1 {
  text-align: center;
  margin-bottom: 82px;
  margin-top: 32px;
}

.ant-menu-title-content {
  border: 1px solid;
    padding: 8px;
}
.ant-menu-item-selected,.ant-menu-item-selected:hover {
  color: #fff !important;
  border-color: #fff;
  background: initial !important;
}

.hero .ant-carousel {
  margin: 0;
}
.hero .slick-slide img {
  height: 82vh !important;
  width: 100% !important;
}
.innerNav img{
  width: 192px;
  height: 36px;
  object-fit: cover;
  margin-bottom: -15px;
}
.sm {
  display: none;
}
.lg {
  display: flex;
}

@media(max-width:768px){
  .batteryStructure{
    padding: 22px 0 !important;
  }
  .content.open {
    margin-top: 32px;
    padding: 0;
}
  .gallaryContainer .slick-slide img {
    width: 78px;
  }
  .product {
    padding: 16px;
  }
  #productsSec .slick-slide img {
    width: 252px;
    margin-bottom: 32px;
  }
  .section-title {
    margin: 32px 0 !important;
  }
  #contact .container> .row{
   flex-direction:column;
   padding: 16px;
  }
  .bikeGallery {
    display: none;
  }
  .footer {
    line-height: 28px;
    margin-top: 52px;
    padding: 12px;
  }
  h1 {
    font-size: 16px;
}
h3 {
  font-size: 16px;
}
  .footerFirst {
    margin: 0;
  }
  #contact .col-md-6 {
    width: 100%;
  }
  .sm {
    display: block;
  }
  .lg {
    display: none;
  }
  .ant-menu {
    padding: 6px;
  }
  .innerNav {
    flex-wrap: wrap;
  }
  .innerNav li {
    padding-inline: 4px !important;
  }
  .hero .slick-slide img {
    height: 200px !important;
    object-fit: contain;
  }
  .headerFixed {
    position: initial;
  }
  .selectSec {
    position: initial;
    width: 100%;
    padding:32px 16px;
  }
  .selectHolder {
    padding: 12px 0;
    max-width: 100%;
    flex-wrap: wrap;
  }
  .aboutContainer>div img {
    width: 100%;
    object-position: initial;
  }
  .hero {
    padding-top: 0;
  }
  .aboutContainer, .progressSec, .batteryStructure, .productsSec, .contact-sec, .gallaryContainer {
    margin-top: 52px;
  }
  .aboutContainer>div {
    width: 100% !important;
    padding: 12px ;
    padding-top: 32px;
  }
  #productsSec .slick-dots {
    display: none !important;
  }
}
